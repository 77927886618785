@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .Input {
    @apply w-full p-2;
    @apply border border-gray-300 rounded-md;
    @apply text-base text-gray-500;
    @apply focus:outline-none focus:border-blue-500;
  }
  .Button {
    @apply px-4 py-2 text-sm;
    @apply text-white bg-blue-500 hover:bg-blue-500 rounded-md;
    @apply focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-blue-500;
    @apply shadow-md;
    @apply sm:px-5 sm:py-2 sm:text-base md:px-6 md:py-3 md:text-lg lg:px-8 lg:py-3 lg:text-xl;
  }
  .Image {
    @apply w-full;
    @apply sm:h-72 md:h-72 lg:w-3/4 xl:w-1/2;
    @apply object-contain;
  }
  .Text {
    @apply text-base text-justify px-4 py-2;
    @apply md:text-base lg:text-lg xl:text-xl 2xl:text-2xl;
  }
  .Container {
    @apply p-1;
  }
  .BackgroundImage{
    @apply h-full;
    @apply bg-center bg-contain;
  }
  .H1 {
    @apply text-2xl md:text-3xl lg:text-4xl xl:text-5xl;
    @apply font-bold;
    @apply my-4 sm:my-6 lg:my-8;
  }
  .H2 {
    @apply text-xl md:text-2xl lg:text-3xl;
    @apply font-semibold;
    @apply my-3 sm:my-4 md:my-5;
  }
  .H3 {
    @apply text-lg md:text-xl lg:text-2xl;
    @apply font-medium;
    @apply mt-3 mb-2 sm:mt-4 sm:mb-3 md:mt-5 md:mb-4;
  }
  .H4 {
    @apply md:text-base md:text-lg lg:text-xl;
    @apply font-medium;
    @apply mt-2 mb-1 sm:mt-3 sm:mb-2 md:mt-4 md:mb-3
  }
  .Select {
    @apply appearance-none block w-full px-3 py-1.5;
    @apply text-base font-normal text-gray-700;
    @apply bg-white bg-clip-padding bg-no-repeat; 
    @apply border border-solid border-gray-300 rounded; 
    @apply transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none;
  }
  .RadioButton {
    @apply h-5 w-5 text-blue-500 flex-shrink-0;
  }
  .ActivityIndicator {
    @apply fixed inset-0 bg-gray-100 bg-opacity-75;
    @apply flex justify-center items-center z-50;
  }
  .Spin {
    @apply animate-spin rounded-full;
    @apply border-t-4 border-b-4 border-blue-500;
    @apply h-8 w-8 sm:h-12 sm:w-12 md:h-16 md:w-16;
  }
}